import React from 'react';
import './Profile.css';

const Profile = () => {
  const contactInfo = {
    name: 'Carlos Gray',
    title: 'Founder',
    organization: 'Task Driven Solutions',
    email: 'cgray@taskdrivensolutions.com',
    phone: '937-838-1888',
    address: '50 Mimosa Dr., Dayton, Ohio 45459, USA',
    websites: [
      'www.taskdrivensolutions.com',
      'www.taskdrivensolutions.com/profile'
    ]
  };

  const handleAddContact = () => {
    const vcfPath = process.env.PUBLIC_URL + '/assets/contact/CarlosGray.vcf';
    const link = document.createElement('a');
    link.href = vcfPath;
    link.download = 'CarlosGray.vcf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="profile-container">
      <div className="profile-content">
        <div className="profile-header">
          <div className="profile-avatar">
            <span className="initials">CG</span>
          </div>
          <h1>{contactInfo.name}</h1>
          <p>{contactInfo.title} at {contactInfo.organization}</p>
        </div>
        <div className="profile-info">
          <div className="info-item">
            <span className="info-label">Name</span>
            <span className="info-value">{contactInfo.name}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Title</span>
            <span className="info-value">{contactInfo.title}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Organization</span>
            <span className="info-value">{contactInfo.organization}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Email</span>
            <span className="info-value">
              <a href={`mailto:${contactInfo.email}`}>
                {contactInfo.email}
              </a>
            </span>
          </div>
          <div className="info-item">
            <span className="info-label">Phone</span>
            <span className="info-value">
              <a href={`tel:${contactInfo.phone}`}>
                {contactInfo.phone}
              </a>
            </span>
          </div>
          <div className="info-item">
            <span className="info-label">Address</span>
            <span className="info-value">{contactInfo.address}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Website{contactInfo.websites.length > 1 ? 's' : ''}</span>
            <span className="info-value">
              {contactInfo.websites.map((url, index) => (
                <React.Fragment key={index}>
                  <a 
                    href={`https://${url}`}
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {url}
                  </a>
                  {index < contactInfo.websites.length - 1 && <br />}
                </React.Fragment>
              ))}
            </span>
          </div>
        </div>
        <button className="download-button" onClick={handleAddContact}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
          </svg>
          Add to Contacts
        </button>
      </div>
    </div>
  );
};

export default Profile;
