import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { servicesOffered, pitches } from '../../misc/objects';
import './Home.css';

function Home() {
    const navigate = useNavigate();

    return (
        <div className="App">
            <Header />

            {/* Hero Section */}
            <section className="hero">
                <div className="hero-content">
                    <h1>
                        <span>WELCOME TO THE FUTURE</span>
                        INNOVATE<br />
                        DOMINATE<br />
                        ELEVATE
                    </h1>
                    <p>Push boundaries. Break limits. Create extraordinary digital experiences that captivate and inspire.</p>
                    <div className="gs-buttons">
                        <button className="cta-button" onClick={() => navigate('/contact')}>
                            LAUNCH PROJECT
                        </button>
                        {/* <button className="cta-button secondary-button" onClick={() => navigate('/features')}>
                            EXPLORE VISION
                        </button> */}
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section className="features">
                <h2>Why Choose Us</h2>
                <div className="features-grid">
                    {servicesOffered.map((feature, index) => (
                        <div
                            key={index}
                            className="feature-item"
                            onClick={() => navigate(`/services/${encodeURIComponent(feature.title.toLowerCase().replace(/[^a-z0-9]+/g, '-'))}`)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    navigate(`/services/${encodeURIComponent(feature.title.toLowerCase().replace(/[^a-z0-9]+/g, '-'))}`);
                                }
                            }}
                        >
                            <img src={feature.image} alt={feature.title} />
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Why Us Section */}
            <section className="why-us">
                <h2>Experience Excellence</h2>
                <div className="pitches-grid">
                    {pitches.map((pitch, index) => (
                        <div
                            key={index}
                            className="pitch-item"
                            onClick={() => navigate(`/services/${encodeURIComponent(pitch.title.toLowerCase().replace(/[^a-z0-9]+/g, '-'))}`)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    navigate(`/services/${encodeURIComponent(pitch.title.toLowerCase().replace(/[^a-z0-9]+/g, '-'))}`);
                                }
                            }}
                        >
                            <div className="pitch-image">
                                <img src={pitch.image} alt={pitch.title} />
                            </div>
                            <div className="pitch-content">
                                <h3>{pitch.title}</h3>
                                <p>{pitch.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Home;
